import React from 'react';
import KatGovLanding from './pages/KatGovLanding';

const App: React.FC = () => (
  <div className="font-sans text-text">
    <KatGovLanding />
  </div>
);

export default App;
